import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-t20baji.png";
import appdownloadicon from "../assets/images/header-appdownload-icon.svg"
import headerserviceicon from "../assets/images/header-service-icon.svg"
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import MenuSidebar from "./MenuSidebar";
import MenuSidebarNew from "./MenuSidebarNew";
import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
//  import t20bajiapp from "../assets/images/t20bajicomapp.apk";

const HeaderNew = () => {
  const {
    user,
    handleShowSidebar,
    showSidebar,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    loginUser,
  } = useContext(AuthContext);
  const navigate = useNavigate()
  const { t } = useTranslation();
  return (
    <>
      <div className="login-header-new">
        <div className="login-reg-wrapper">
        <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <MdOutlineSubject className="fs-4" />
              </button>
          <figure onClick={() => navigate('/')}>
          {/* {isEmpty(user) ? (
            <img
              style={{
                height: "50px",
              }}
              src={logo}
              alt=""
            />):  */}
            <img
            style={{
              height: "32px",
            }}
            src={logo}
            alt=""
          />
          {/* } */}
          </figure>
          {isEmpty(user) ? (
            <figcaption>
              {/* <Link to="/login">Log In /</Link> */}
              {/* <Link to="/login">{t("Login_Registration")}</Link> */}
            </figcaption>
          ) : (
            <>
              {/* <div className="d-flex align-items-lg-start for-ref after-login w-100">
                {refreshAmountLoader ? (
                  <div class="loader-outer">
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: "0s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".1s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".2s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".3s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".4s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".5s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".6s" }}
                    ></span>
                    <span
                      class="loader-inner-icon"
                      style={{ animationDelay: ".7s" }}
                    ></span>
                  </div>
                ) : (
                  <div
                    
                    className="header-outer"
                  >
                    <div className="paise" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "10px" }}>
                        <span>{user?.user?.username}</span>
                        <div className="d-flex align-items-center">
                          <strong className="header-font-size">
                            PBU{" "}
                            <span style={{ marginLeft: "3px", color: "white" }}>
                              {userCoins?.balance?.toFixed()}
                            </span>
                          </strong>
                          <strong className="header-font-size">
                            Exp{" "}
                            <span
                              className="text-white"
                              style={{ marginLeft: "3px", fontWeight: "700" }}
                            >
                              ({userCoins?.exp?.toFixed()})
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div onClick={amounutRefresh}>
                        <HiOutlineRefresh
                          size={20}
                          className={refreshAmountLoader ? "refresh-icon" : ""}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div> */}
              {/* <button
                className="bg-transparent border-0 text-white"
                onClick={() => handleShowSidebar()}
              >
                <MdOutlineSubject className="fs-4" />
              </button> */}
            </>
            // <div
            //   style={{
            //     display: "flex",
            //     justifyContent: "space-between",
            //     alignItems: "center",
            //     width: "100%",
            //   }}
            // >
            //   {" "}
            //   <figcaption>+{user?.user?.username}</figcaption>
            //   <button
            //     className="bg-transparent border-0 text-white"
            //     onClick={() => handleShowSidebar()}
            //   >
            //     <MdOutlineSubject className="fs-4" />
            //   </button>
            // </div>
          )}
          <div className="header-right-btn-group">
          
            <a href={"#"}  download="t20baji" target='_blank'><img src={appdownloadicon} /><span>App</span></a>
            <a href="#"><img src={headerserviceicon}/><span >Help</span></a>
            </div>
        </div>
        {showSidebar && (
          <MenuSidebarNew
            cancelMenu={handleShowSidebar}
            showSidebar={showSidebar}
          />
        )}

        {showSidebar && (
          <div className="overlay" onClick={() => handleShowSidebar()}></div>
        )}
      </div>
    </>
  );
};

export default HeaderNew;
